<template>
  <div>
    <div class="field">
      <div class="control is-expanded">
        <bulma-autocomplete
          :id="id+ '_address'"
          placeholder="Start typing address"
          v-model="address.address1"
          :data="addresses.data"
          field="street"
          :loading="addresses.loading"
          @input="searchAddress(address, $event)"
          @select="pickAddress(address, $event)"
          :class-prop="!v.address1.$error ? '' : 'is-danger'"
          :focus-inserted="address.isNew"
          :disabled="disabled">
          <template slot-scope="props">
            <div>
              <span class="icon is-small">
                <i class="mdi mdi-map-marker" />
              </span>
              {{ `${props.option.street}, ${props.option.secondary_text}` }}
            </div>
          </template>
        </bulma-autocomplete>
        <span
          class="help is-danger"
          v-if="v.address1 && !v.address1.required">Address is required
        </span>
      </div>
    </div>
    <div class="field">
      <div class="control is-expanded">
        <input
          class="input fix-rounded"
          type="text"
          autocomplete="off"
          v-model="address.address2"
          placeholder="Address Line 2"
          :disabled="disabled">
      </div>
    </div>
    <div v-if="showAddress3"
      class="field">
      <div class="control is-expanded">
        <input
          class="input fix-rounded"
          type="text"
          autocomplete="off"
          v-model="address.address3"
          placeholder="Address Line 3"
          :disabled="disabled">
      </div>
    </div>
    <div class="field">
      <div class="columns">
        <div class="column">
          <label class="label">Suburb</label>
          <div class="control">
            <bulma-autocomplete
              placeholder="Suburb"
              v-model="address.suburbText"
              :data="suburbs.data"
              field="suburb"
              :loading="suburbs.loading"
              @input="searchSuburb(address, $event)"
              @select="pickSuburb(address, $event)"
              :class-prop="!v.suburbText.$error ? 'fix-rounded' : 'fix-rounded is-danger'"
              :disabled="disabled">
              <template slot-scope="props">
                <div>
                  <span class="icon is-small">
                    <i class="mdi mdi-map-marker" />
                  </span>
                  {{ `${props.option.suburb}, ${props.option.state}, ${props.option.country}` }}
                </div>
              </template>
            </bulma-autocomplete>
            <span
              class="help is-danger"
              v-if="v.suburbText && !v.suburbText.required">Suburb is required
            </span>
          </div>
        </div>
        <div class="column is-4">
          <label class="label">State</label>
          <div class="control">
            <input
              class="input fix-rounded"
              type="text"
              v-model="address.stateCode"
              placeholder="State Code"
              v-fq-validate="v.stateCode"
              @input="onStateChanged"
              :disabled="disabled">
            <span
              class="help is-danger"
              v-if="v.stateCode && !v.stateCode.required">State is required
            </span>
          </div>
        </div>
        <div class="column is-2">
          <label class="label">Postcode</label>
          <div class="control">
            <input
              class="input fix-rounded"
              type="text"
              v-model="address.suburbPostcode"
              placeholder="Postcode"
              v-fq-validate="v.suburbPostcode"
              :disabled="disabled">
            <span
              class="help is-danger"
              v-if="v.suburbPostcode && !v.suburbPostcode.required">Postcode is required
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">Country</label>
      <div class="field-body">
        <div class="control">
          <input
            class="input is-uppercase fix-rounded"
            type="text"
            v-model="address.countryCode"
            placeholder="Country Code"
            v-fq-validate="v.countryCode"
            maxlength="2"
            @input="onCountryChanged"
            :disabled="disabled">
          <span
            class="help is-danger"
            v-if="v.countryCode && !v.countryCode.required">Country is required
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InputValidationDirective } from '@/components/directives'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import _debounce from 'lodash/debounce'
import Countries from '@/assets/json/countries.json'
import Neighbours from '@/assets/json/neighbours.json'

export default {
  name: 'GoogleAutocomplete',
  components: {
    BulmaAutocomplete
  },
  mixins: [InputValidationDirective],
  props: {
    id: {
      type: String,
      default: ''
    },
    address: null,
    v: null,
    bounds: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showAddress3: {
      type: Boolean,
      default: false
    },
    countryCode: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      addresses: {
        data: [],
        loading: false
      },
      suburbs: {
        data: [],
        loading: false
      }
    }
  },
  computed: {
    countryCodes() {
      const countryCode = this.countryCode ? this.countryCode : this.$company.info.countryCode
      let neighbours = Neighbours.find(n => n.countryCode === countryCode) || []
      return [countryCode, ...neighbours.neighbours]
    }
  },
  methods: {
    searchSuburb: _debounce(function(address, key) {
      this.v.suburbText.$touch()
      this.suburbs.loading = true
      if (key.length > 0) {
        const searchOptions = {
          input: key,
          types: ['(cities)'],
          componentRestrictions: {
            country: this.countryCodes
          }
        }

        if (this.bounds) {
          searchOptions.bounds = this.bounds
        }

        var service = new window.google.maps.places.AutocompleteService()
        service.getPlacePredictions(searchOptions, (predictions, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            // alert(status)
            return
          }
          const suburbs = []
          predictions.forEach(function(prediction) {
            if (prediction.terms.length === 2) {
              suburbs.push({
                id: prediction.place_id,
                suburb: prediction.terms[0].value,
                state: prediction.terms[0].value,
                country: prediction.terms[1].value
              })
            } else if (prediction.terms.length === 3) {
              suburbs.push({
                id: prediction.place_id,
                suburb: prediction.terms[0].value,
                state: prediction.terms[1].value,
                country: prediction.terms[2].value
              })
            }
          })
          this.suburbs.data = suburbs
        })
      }
      this.suburbs.loading = false
    }, 500),

    pickSuburb: function(address, e) {
      if (!e) {
        return
      }
      const vm = this
      var service = new window.google.maps.Geocoder()

      service.geocode(
        {
          placeId: e.id
        },
        function(results, status) {
          if (status === 'OK') {
            const place = results[0]

            for (let i = 0; i < place.address_components.length; i++) {
              const addressType = place.address_components[i].types[0]

              switch (addressType) {
                case 'locality':
                  address.suburbText = place.address_components[i]['long_name']

                  vm.v.suburbText.$touch()
                  break

                case 'postal_code':
                  address.suburbPostcode = place.address_components[i]['short_name']

                  vm.v.suburbPostcode.$touch()
                  break

                case 'administrative_area_level_1':
                  address.stateText = place.address_components[i]['long_name']
                  address.stateCode = place.address_components[i]['short_name']

                  vm.v.stateText.$touch()
                  vm.v.stateCode.$touch()
                  break

                case 'country':
                  address.countryText = place.address_components[i]['long_name']
                  address.countryCode = place.address_components[i]['short_name']

                  vm.v.countryText.$touch()
                  vm.v.countryCode.$touch()
                  break
              }
            }
          }
        }
      )
    },

    searchAddress: _debounce(function(address, key) {
      this.v.address1.$touch()

      this.addresses.loading = true

      if (key.length > 0) {
        const searchOptions = {
          input: key,
          types: ['geocode'],
          componentRestrictions: {
            country: this.countryCodes
          }
        }

        if (this.bounds) {
          searchOptions.bounds = this.bounds
        }

        var service = new window.google.maps.places.AutocompleteService()
        service.getPlacePredictions(searchOptions, (predictions, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            // alert(status)
            return
          }

          const addresses = []
          predictions.forEach(function(prediction) {
            // console.log(prediction)
            addresses.push({
              id: prediction.place_id,
              street: `${prediction.structured_formatting.main_text}`,
              secondary_text: prediction.structured_formatting.secondary_text
            })
          })
          this.addresses.data = addresses
        })
      }
      this.addresses.loading = false
    }, 500),

    pickAddress: function(address, e) {
      if (!e) {
        return
      }

      const vm = this
      var service = new window.google.maps.Geocoder()

      service.geocode(
        {
          placeId: e.id
        },
        function(results, status) {
          if (status === 'OK') {
            // touch everything
            vm.v.$touch()

            const place = results[0]

            if (place.address_components !== undefined) {
              // reset address1, otherwise street only search will double up
              address.address1 = ''
              for (let i = 0; i < place.address_components.length; i++) {
                const addressType = place.address_components[i].types[0]

                switch (addressType) {
                  case 'street_number':
                    address.address1 = place.address_components[i]['short_name']
                    break

                  case 'route':
                    address.address1 += ' ' + place.address_components[i]['long_name']
                    break

                  case 'locality':
                    address.suburbText = place.address_components[i]['long_name']
                    break

                  case 'postal_code':
                    address.suburbPostcode = place.address_components[i]['short_name']
                    break

                  case 'administrative_area_level_1':
                    address.stateText = place.address_components[i]['long_name']
                    address.stateCode = place.address_components[i]['short_name']
                    break

                  case 'country':
                    address.countryText = place.address_components[i]['long_name']
                    address.countryCode = place.address_components[i]['short_name']
                    break
                }
              }
            }
          }
        }
      )
    },
    onStateChanged(event) {
      this.address.stateText = event.target.value
    },
    onCountryChanged(event) {
      const country = Countries.find(c => c['alpha-2'].toUpperCase() === this.address.countryCode.toUpperCase())
      this.address.countryText = country ? country['name'] : ''
    }
  }
}
</script>

<style lang="scss" scoped>
input.fix-rounded {
  border-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
</style>
